$( document ).ready(function() {

    // Remove all mobile-specific classes when window size goes above 768px
    $(window).on('resize', function(){
        if ($(window).width() >= 1200) {
            $('html').removeClass('mobile-menu--active');
            $('body').removeClass('mobile-menu--active');
            $(this).parent().siblings('.menu-item').removeClass('hidden');
            $(this).parent().removeClass('submenu-is-active');
        }
    });

    // Only fadeIn/fadeOut mega-menu on hover on "desktop" and above
    $( '.menu-item.has-mega-menu' ).hover(showMegaMenu,hideMegaMenu);
    function showMegaMenu() {
        if ($(window).width() >= 1200) {
            $( this ).children('.mega-menu').fadeIn( "fast" );
        };
    };
    function hideMegaMenu() {
        // Only fadeOut mega-menu on hover on "desktop" and above
        if ($(window).width() >= 1200) {
            $( this ).children('.mega-menu').fadeOut( "fast" );
        };
    };

    // Mobile menu
    var isActive = false;
    $('.menu-item.has-mega-menu .menu-item__link').click(function(e) {
        if ($(window).width() < 1200) {
            e.preventDefault();
            var clickedMenuItem = $(this);
            clickedMenuItem.toggleClass('is-active');
            if (!isActive) {
                clickedMenuItem.siblings('.mega-menu').fadeIn( "fast" );
                isActive = true;
            } else {
                clickedMenuItem.children('.mega-menu').fadeOut( "fast" );
                isActive = false;
            }
            $('.menu-item').toggleClass('hidden');
        }
    });
    $('.menu__back').click(function() {
        if ($(window).width() < 1200) {
            var clickedMenuItem = $(this);
            if (!isActive) {
                $('.mega-menu').fadeIn( "fast" );
                isActive = true;
            } else {
                $('.mega-menu').fadeOut( "fast" );
                isActive = false;
            }
            $('.menu-item.hidden').removeClass('hidden');
        }
    });

    var navMobileIsActive = false;
    $('button.hamburger').click(function() {
        $(this).toggleClass('is-active');
        if (!navMobileIsActive) {
            $('html').addClass('mobile-menu--active');
            $('body').addClass('mobile-menu--active');
            $('.menu').addClass('menu--mobile-active');
            setTimeout(function() {
                $('.menu').addClass('menu--mobile-visible');
            }, 10);
            navMobileIsActive = true;
        } else {
            $('html').removeClass('mobile-menu--active');
            $('body').removeClass('mobile-menu--active');
            $('.menu').removeClass('menu--mobile-visible');
            setTimeout(function() {
                $('.menu').removeClass('menu--mobile-active');
            }, 300);
            navMobileIsActive = false;
        }
    });

});
