var selectionSlider = tns({
  container: '.selection-teasers .carousel',
  items: 1,
  slideBy: 1,
  autoplay: true,
  autoplayButtonOutput: false,
  prevButton: '.selection-teasers .carousel-prev',
  nextButton: '.selection-teasers .carousel-next',
  responsive: {
    700: {
      items: 2,
      gutter: 50
    }
  }
});
