var newsSlider = tns({
  container: '.news-teasers .carousel',
  items: 1,
  slideBy: 1,
  autoplay: true,
  navContainer:'.news-car-dots',
  autoplayButtonOutput: false,
  prevButton: '.news-teasers .carousel-prev',
  nextButton: '.news-teasers .carousel-next',
  responsive: {
    700: {
      items: 2.5,
      gutter: 80
    }
  }
});



var slideCount;
window.onload = function() {
  var info = newsSlider.getInfo()
  slideCount = info.slideCount;
  indexCurrent = info.displayIndex;
  info.slideItems[indexCurrent + 1].classList.add('tns-slide-center');
}

newsSlider.events.on('indexChanged', function(info, eventName) {
    var info = newsSlider.getInfo();
    indexCurrent = info.displayIndex;
    slideCount = info.slideCount;

    $( '#news-current-slide' ).text(function (i, n) {
        return (info.displayIndex < 10 ? "0" : "") + info.displayIndex;
    });
    // if (indexCurrent == slideCount) {
    //     info.slideItems[0].classList.remove('tns-slide-center');
    // }
    // if (indexCurrent == 1) {
    //     info.slideItems[slideCount].classList.remove('tns-slide-center');
    // }

});

document.querySelector('.news-slider-counter .carousel-prev').onclick = function () {
    var info = newsSlider.getInfo();
    indexCurrent = info.displayIndex;
    slideCount = info.slideCount;
    info.slideItems[indexCurrent + 1].classList.add('tns-slide-center');
    info.slideItems[indexCurrent + 2].classList.remove('tns-slide-center');
}

document.querySelector('.news-slider-counter .carousel-next').onclick = function () {
    var info = newsSlider.getInfo();
    indexCurrent = info.displayIndex;
    info.slideItems[indexCurrent + 1].classList.add('tns-slide-center');
    info.slideItems[indexCurrent].classList.remove('tns-slide-center');
}